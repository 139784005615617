import React from 'react'

const Skillbar = ({percent, titleColor, icon, name, barColor}) => {
    return (
        <div className="skillbar" data-percent={percent}>
                <div className="skillbar-title" style={{background: titleColor}}>
                    <span>
                    <i className={icon}></i> {name}
                    </span>
                </div>
                <div className="skillbar-bar" style={{background: barColor}}></div>
                <div className="skill-bar-percent">{percent}</div>
            </div>
    )
}

export default Skillbar
