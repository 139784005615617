import React from 'react'

const wrapStyles = {
    height: '12vh',
    width: '100%',
    backgroundColor: '#dad878',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5vh 0 ',
    border: '2px solid',
    margin: '7vh 0',
}
const headerStyles = {
    color: '#111',
    fontFamily: 'Open Sans Condensed, sans-serif',
    fontSize: '10vh',
    fontWeight: '700',
    textShadow: '5px 4px 2px rgb(161, 161, 161)',
    margin: '0 0 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    position: 'absolute',
}

export const Header = ({name}) => {
    return (
        <div className={name
            .toLowerCase()
            .replace(/\s/g, '')
            + 'Wrapper'}
        style={wrapStyles}
        >
            <h2 
            className='header2'
            style={headerStyles}
            >{name}</h2>
        </div>
    )
}

export default Header
