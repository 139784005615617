import React, {useEffect, useState} from 'react'
import Recipe from './components/Recipe'
import './App.css'

const RecipeApp = () => {

    const APP_ID = 'c0a957e2';
    const APP_KEY = '1635d768cdea59eb70f9078d35c9fc04'

    const [recipes, setRecipes] = useState([])
    const [search, setSearch] = useState('')
    const [query, setQuery] = useState('chicken')
    
    useEffect(() => {
      const getRecipes = async () => {
        const response = await fetch(`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${APP_KEY}`)
        const data = await response.json();
        setRecipes(data.hits)
      }
      getRecipes()
    }, [query])


    const updateSearch = e => {
      setSearch(e.target.value)
    }

    const getSearch = e => {
      e.preventDefault()
      setQuery(search)
      setSearch('')
    }
    
    return (
        <div className='App'>
            <form
              className='search-form'
              onSubmit={getSearch}
            >
                <input 
                  className='search-bar' 
                  type='text' 
                  value={search}
                  onChange={updateSearch}
                  placeholder='What do you feel like eating?'
                />
                <button 
                    
                    className='search-button'
                    type='submit'>Search
                >
                </button>
            </form>
            <div className='recipes'>
            {recipes.map(recipe => (
              <Recipe
              key={recipe.recipe.label}
              title={recipe.recipe.label}
              dietLabels={recipe.recipe.dietLabels}
              calories={recipe.recipe.calories}
              image={recipe.recipe.image}
              ingredients={recipe.recipe.ingredients}
              />
            ))}
           </div>
        </div>
    )
}

export default RecipeApp
