import React from 'react'

let projectLinks = {
    a: 'LiteraryTimeline/index.html',
    b: 'AnimalSounds/index.html',
    c: 'RecipeApp',
    d: 'FlexboxVisual/index.html',
    e: 'TypeaheadSearchbar/index.html',
    f: '',
    g: '',
}

export const ViewProject = ({gridLetter}) => {

  // get the link that fits the clicked element
function handleLink() {
    var filtered = {}
    for(var key in projectLinks) { 
      if(key[0] === gridLetter){
        filtered[key] = projectLinks[key]
      }
    }
    let link = Object.values(filtered)
return link

}
  
        return (
                <div className='viewDiv'>
                  <a 
                      href={handleLink()}
                      target='_blank'
                      rel='noopener noreferrer'                                 
                      className="viewProject"
                      >view project
                  </a>
              </div>
        )

        }
