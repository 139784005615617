import React, { Component } from 'react'
import { Languages } from './Languages'
import { MyFace } from './MyFace'
import './banner.css'

export class Banner extends Component {
    render() {
        // getting the height of the navbar so that it can be substracted from the 100vh of #parallax
        
        const navbarCorrection = {
            height: `calc(100vh - ${this.props.getNavbarHeight})`
        }

        return (
            <div id='parallax'
                style={navbarCorrection}
                >
                <Languages />
                <MyFace />
                <div 
                className='aboutMe'
                >
                    <p >Hello, I'm Jens - a frontend developer from Aarhus, Denmark</p>
                </div>

            </div>
        )
    }
}

export default Banner
