import React from 'react'

let projectText = {
    a: 'This timeline of literary history has a neat and visually pleasing UI. The most interesting part is, however, how the data is organized behind the scenes. The JavaScript uses RegExp, JQuery and various filter methods to extract the author entries from a set of raw data and organize them into their corresponding literary periods. In its current state, the timeline is a small prototype of what could be a much larger interactive learning platform.',
    b: `This sweet and simple app was made purely with the intention of entertaining my one-year-old daughter, Clara, as well as training her ear to differentiate between notes. Research shows that if you pair pitch with meaning early in a child's life, it is possible to help the child develop perfect pitch. To this end, Animal Sounds pairs each note to a picture of an animal, which pops up on the screen when a note button is pushed`,
    c: 'This recipe app is built in React and uses hooks such as useEffect and useState to fetch data from an API of over 700.000 food items hosted by Edamam.com. The user types in a food, and the useEffect updates the useState when a search is submitted and builds a list of Recipe components with ingredients, calories and more.',
    d: 'Inspired by a Wes Bos tutorial, this panel gallery uses flexbox and simple transitions to achieve a highly responsive and and intuitive open/close UI. The project is an infographic showcasing the "big five" personality traits. The URLs have been cleverly interpolated so that if you click on the middle text of an open panel, you will be redirected to the relevant Wikipedia article.',
    e: 'This app fetches American city and state names from an online .json file and allows users to search and see population sizes. One of the cool features of the searchbar is a typeahead function that updates the results whenever a "keyup" or "change" event i fired. It is built from plain JavaScript and makes use of Fetch API along with some RegExp.',
    f: 'I am in the process of building the website, and the portfolio will be updated soon.',
    g: 'I am in the process of building the website, and the portfolio will be updated soon.',
}


export const TileInfo = ({projectTitle, tileText, gridLetter}) => {
  
    function handleDescription() {
        var filtered = {}
        for(var key in projectText) { 
          if(key[0] === gridLetter){
            filtered[key] = projectText[key]
          }
        }
        let text = Object.values(filtered)
    return text
    
    }
    
        return (
            <div className='tileInfo'>
                <h2 className='projectTitle'>{projectTitle}</h2>
                <p className={tileText ? 'tileText' : 'hidden'}>{handleDescription()}</p>
            </div>
        )
}

export default TileInfo
