import React from 'react';
import {Timeline, TimelineEvent} from './timeline1/components/index'
import './timeline1/components/styles.js'

export class WorkTimeline extends React.Component {
    render() {
        return (
    <Timeline>
            <TimelineEvent 
                           createdAt="2018-2019"
                           icon={<i className="material-icons md-18">work</i>}
            >
               Teacher at Randers Statsskole – English
            </TimelineEvent>
            <TimelineEvent 
                           createdAt="2017-2018"
                           icon={<i className="material-icons md-18">work</i>}
            >
               Teacher at Ikast-Brande Gymnasium – English and Theory of Knowledge
            </TimelineEvent>
            <TimelineEvent
                
                createdAt="2016-2017"
                icon={<i className="material-icons md-18">work</i>}
            >
                Teacher at Vestjysk Gymnasium Tarm – English
            </TimelineEvent>
            <TimelineEvent
                
                createdAt="2015-2016"
                icon={<i className="material-icons md-18">work</i>}
            >
                Teacher at Ikast-Brande Gymnasium – English
            </TimelineEvent>
            <TimelineEvent
                
                createdAt="2014-2016"
                icon={<i className="material-icons md-18">work</i>}
            >
                Volunteer translator and editor at the publishing company Philosophia
            </TimelineEvent>
            <TimelineEvent
                
                createdAt="2011"
                icon={<i className="material-icons md-18">work</i>}
            >
                Instructor in academic research at Aarhus University
            </TimelineEvent>
            <TimelineEvent
                
                createdAt="2011"
                icon={<i className="material-icons md-18">work</i>}
            >
                Mentor for exchange students at Aarhus University
            </TimelineEvent>
    </Timeline>
        )
    }
}