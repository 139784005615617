import React from 'react'
import { TileInfo } from './TileInfo'

export const ProjectTile = ({gridExpand, gridLetter, projectTitle}) => {
   
    return (
        <div 
        onClick={gridExpand}
        className={gridLetter + ' box'}
        >
        <TileInfo
        projectTitle={projectTitle}
        />

    </div>
            )
    }


