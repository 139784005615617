import React from 'react';
import './Navbar.css';
import '../App.css'
import { NavLink } from './NavLink'
export class Navbar extends React.Component {

   constructor(props) {
       super(props)
       
       this.handleScroll = this.handleScroll.bind(this);

   }
   
      componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const navbar = document.querySelector('#navbar')
        const navbarHeight = navbar.offsetHeight + 'px';
        this.props.handleNavbarHeight(navbarHeight)
      };
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      };
      
      handleScroll() {
        const nav = document.querySelector('#navbar');
        const hiddenNav = document.querySelector('.hiddenNav');
        // #navbar offsetTop changes to 0 once the position becomes fixed - therefore we set the offsetTop of a non-fixed, identical div (hiddenNav)
        const hiddenNavTop = hiddenNav.offsetTop

        if (window.scrollY >= (hiddenNavTop)) {
            nav.style.position = '';
            nav.classList.add('sticky'); 
        } else {
            nav.style.position = 'relative';
            nav.style.bottom = 0;
            nav.classList.remove('sticky');
        }
        }
    handleNavbarHeight(e) {
    }
    render() {
        return (
            <div 
                id='navWrapper'
                style={{height: this.props.getNavbarHeight}}
                >
            <div className='hiddenNav'></div>
                
                <div 
                id='navbar'>
                <ul>
                    <NavLink
                    href='parallax'
                    name='Home'
                    />
                    <NavLink
                    href='projectsWrapperOuter'
                    name='Projects'
                    />
                    <NavLink
                    href='skillsWrapper'
                    name='Skills'
                    />
                    <NavLink
                    href='cvWrapper'
                    name='Curriculum Vitae'
                    />
                    <NavLink
                    href='contactMe'
                    name='Contact Me'
                    />
                </ul>
                </div>

            </div>
        )
    }
}