import React from 'react'
import { Link } from 'react-scroll'

export const NavLink = ({href, name}) => {
        return (
            <li>
                <a>
                    <Link  to={href} spy={true} smooth={true} duration={500} offset={-54}>{name}</Link>
                </a>
            </li>
        )
}

