import React, { Component } from 'react'
import './projects.css';
import { ProjectTile } from './ProjectTile';
import { ViewProject } from './ViewProject'
import { TileInfo } from './TileInfo'

// let tileSpecs = {
//   top: '',
//   left: '',
//   width: '',
//   height: '',
// }

// let gridSpecs = {
//   top: '',
//   left: '',
//   width: '',
//   height: '',
// }

// let gridTitleSpecs = {
//   width: '',
//   height: ''
// }



export class ProjectShow extends Component {
  constructor(props) {
    super(props)
    this.gridExpand = this.gridExpand.bind(this)
    this.handleProjectViewToggle = this.handleProjectViewToggle.bind(this)
    this.handleTileText = this.handleTileText.bind(this)

    this.state = {
      projectViewToggle: false,
      tileTextToggle: false,
      currentLetter: 'a',
      currentTitle: '',
      specs: {
        tileSpecs: {
          top: '',
          left: '',
          width: '',
          height: '',
        },
        gridSpecs: {
          top: '',
          left: '',
          width: '',
          height: '',
        },
        gridTitleSpecs: {
          width: '',
          height: ''
        }
      }
    }
  }
  handleTileText() {
    const currentState = this.state.tileTextToggle;
    this.setState({ tileTextToggle: !currentState })
  }
 
  handleProjectViewToggle() {
    const currentState = this.state.projectViewToggle;
    this.setState({ projectViewToggle: !currentState })
  }
  
  tileRetract(e) {
    // when an expanded tileDiv is clicked, it will retract to the original grid position
    this.handleTileText()
    let tileDiv = document.querySelector('.tile')
    
    let projectsWrapper = document.querySelector('#projectsWrapper')
    let contentWrapper = document.querySelector('.contentWrapper')
    let tileInfo = tileDiv.children[0];
    let tileText = tileInfo.children[1]
    let stateTileSpecs = this.state.specs.tileSpecs;
    
    tileInfo.className = 'tileInfo infoRetract'
    document.documentElement.style
    .setProperty('--tile-top', `${stateTileSpecs.top}px`);
    document.documentElement.style
    .setProperty('--tile-left', `${stateTileSpecs.left}px`);
    document.documentElement.style
    .setProperty('--tile-width', `${stateTileSpecs.width}px`);
    document.documentElement.style
    .setProperty('--tile-height', `${stateTileSpecs.height}px`);
    
    tileDiv.className = `${this.state.currentLetter} tile retract`
    
    if (this.state.currentTitle !== 'Coming Soon') {
        this.handleProjectViewToggle()
    }
    
    
    tileDiv.addEventListener('animationend', reset)
    function reset(e) {
      setTimeout(() => {
          tileInfo.className = 'tileInfo'
          tileText.className = 'tileText hidden'
          contentWrapper.style.display = 'none';
          projectsWrapper.style.display = 'block'


        }, 200)

      tileDiv.removeEventListener('animationend', reset)
    }
  }

  gridExpand(e) {
    let tile;
    // prevent bubbling when clicking header or text elements
    if (e.target.classList[0].length === 1) {
      tile = e.target
    } else if (e.target.classList[0] === 'tileInfo') {
      tile = e.target.parentElement
    } else if (e.target.classList[0] === 'tileText') {
      tile = e.target.parentElement.parentElement
    } else if (e.target.classList[0] === 'projectTitle') {
      tile = e.target.parentElement.parentElement
    }

    // DOM-queries
    let gridLetter = tile.classList[0];
    let grid = document.querySelector('.grid')
    let gridTitle = tile.children[0].children[0]
    let tileDiv = document.querySelector('.tile')
    let tileInfo = document.querySelector('.tile .tileInfo')
    let tileText = tileInfo.children[1]
    let contentWrapper = document.querySelector('.contentWrapper')
 

    // the states are updated, and a callback function is fired to prevent asynchronicity
    this.setState({ 
      currentLetter: gridLetter,
      currentTitle: gridTitle.textContent,
      specs: {
        tileSpecs: {
          top: tile.offsetTop,
          left: tile.getBoundingClientRect().left,
          width: tile.getBoundingClientRect().width,
          height: tile.getBoundingClientRect().height
        },
        gridSpecs: {
          top: grid.offsetTop,
          left: grid.getBoundingClientRect().left,
          width: grid.getBoundingClientRect().width,
          height: grid.getBoundingClientRect().height
        },
        gridTitleSpecs: {
          width: gridTitle.offsetWidth,
          height: gridTitle.offsetHeight
        }
      }
    }, () => {
      // animation begins
      contentWrapper.style.display = 'block'
      tileDiv.className = `${gridLetter} expand tile`
  
      let stateTileSpecs = this.state.specs.tileSpecs
      let stateGridSpecs = this.state.specs.gridSpecs
      let stateGridTitleSpecs = this.state.specs.gridTitleSpecs
  
      tileDiv.style.top = stateTileSpecs.top + 'px'
      tileDiv.style.left = stateTileSpecs.left + 'px'
      tileDiv.style.width = stateTileSpecs.width + 'px'
      tileDiv.style.height = stateTileSpecs.height + 'px'
      
      document.documentElement.style
      .setProperty('--grid-top', `${stateGridSpecs.top}px`);
      document.documentElement.style
      .setProperty('--grid-left', `${stateGridSpecs.left}px`);
      document.documentElement.style
      .setProperty('--grid-width', `${stateGridSpecs.width}px`);
      document.documentElement.style
      .setProperty('--grid-height', `${stateGridSpecs.height}px`);
      
      document.documentElement.style
      .setProperty('--tileDivTitle-width', `${stateGridTitleSpecs.width}px`);
      document.documentElement.style
      .setProperty('--tileDivTitle-height', `${stateGridTitleSpecs.height}px`);
      
    })
    
    let anim = () => {
      if (tileDiv.offsetWidth === Math.round(this.state.specs.gridSpecs.width)) {
        tileInfo.className += ' infoExpand'
        setTimeout(() => {
          this.handleTileText()
          tileText.className += ' fadeInOpacity'
          if (this.state.currentTitle !== 'Coming Soon') {
            this.handleProjectViewToggle()
            let viewDiv = document.querySelector('.viewDiv')
            viewDiv.className += ' fadeInOpacity'


          }
        },400)
    tileDiv.removeEventListener('animationend', anim)
  }
  
    }
    
    tileDiv.addEventListener('animationend', anim)
}

    
    render() {
        return (
          <div> 
          <div id='projectsWrapper'>
                <div 
                className='grid unclicked'>
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='a'
                    projectTitle='Literary Timeline from Raw Data'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='b'
                    projectTitle='Animal Sounds'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='c'
                    projectTitle='Recipe App'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='d'
                    projectTitle='Flexbox visual'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='e'
                    projectTitle='Typeahead searchbar'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='f'
                    projectTitle='Coming Soon'
                  />
                  <ProjectTile
                    getLetter={this.getLetter}
                    gridExpand={this.gridExpand}
                    gridLetter='g'
                    projectTitle='Coming Soon'
                  />
              </div>
          </div>
        <div className='contentWrapper'>
          <div className='tile'
           onClick={(e) => {
             const gridWidth = Math.round(this.state.specs.gridSpecs.width)
           if (e.target.offsetWidth === gridWidth && this.state.currentTitle === 'Coming Soon') {
             this.tileRetract() 
           } else if (e.target.offsetWidth === gridWidth && this.state.projectViewToggle === true) {
             this.tileRetract()
           }
            }
          }
           >
            <TileInfo
            projectTitle={this.state.currentTitle}
            gridLetter={this.state.currentLetter}
            tileText={this.state.tileTextToggle}

            />
            {this.state.projectViewToggle ? 
            
            <ViewProject
            gridLetter={this.state.currentLetter}
            />
            : null
            }
           </div>
        </div>
        </div>
        )
    }
}

export default ProjectShow;
