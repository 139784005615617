import React from 'react';
import './skills.css';
import Skillbar from './Skillbar'

export class Skills extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             isInViewport: false,
        }
    this.handleSroll=this.handleSroll.bind(this)
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleSroll)
        window.addEventListener('scroll', this.handleSroll)
    }
    
    handleSroll(e){
         // Skillbar animation when fully in viewport  
         
         let skillArray = Array.from(document.querySelectorAll('.skillbar'))
         
 
          function isFullyInViewport(el) {
             let elementTop = el.offsetTop;
             let elementBottom = elementTop + el.offsetHeight;
             let viewportTop = window.scrollY
             let viewportBottom = viewportTop + window.innerHeight
              return elementTop >= viewportTop && elementBottom <= viewportBottom;

         }
        function findSkill() {
            let skillInView
         for (let i = 0; i < skillArray.length; i++) {
             if (isFullyInViewport(skillArray[i])) {
                skillInView = skillArray[i]
                 
             }
         }
         if (skillInView !== undefined){

             let bar = skillInView.children[1]
             let data = skillInView.getAttribute('data-percent')
             bar.style.width = data;
            }
            
            // failsafe for skillbars remaining unexpanded (probably because of mousewheel scrolling)
            // let allBarsArray = Array.from(document.querySelectorAll('.skillbar'))
            // let barLoop = allBarsArray.map(bar => bar.children[1].style.width)
            
            // let barCount = barLoop.filter((bar) => {
            //     return bar
            // })
            
            // if (barCount.length >= 5) {
            //     let skillbar = document.querySelector('.skillbar-bar')
            //     let data = skillbar.parentNode.getAttribute('data-percent')
            //     console.log(data);
            //     skillbar.style.width = data;

              
        

        }
        findSkill()

    }

    render() {
        return (
        <div id='skillsWrapper'>
            <Skillbar
            percent = '90%'
            titleColor = '#d35400'
            icon = "devicon-html5-plain-wordmark"
            name = 'HTML5'
            barColor = '#e67e22'
            />
            <Skillbar 
            percent = '70%'
            titleColor = '#2980b9'
            icon = "devicon-css3-plain-wordmark"
            name = 'CSS3'
            barColor = '#3498db'
            />
            <Skillbar
            percent = '60%'
            titleColor = '#90832f'
            icon = "devicon-javascript-plain"
            name = 'JavaScript'
            barColor = '#f0db4f'
            />
            <Skillbar
            percent = '60%'
            titleColor = '#008199'
            icon = 'devicon-react-original-wordmark'
            name = 'React.js'
            barColor = '#00d8ff'
            />
            <Skillbar 
            percent = '60%'
            titleColor = '#2c3e50'
            icon = "devicon-jquery-plain-wordmark"
            name = 'JQuery'
            barColor = '#2c3e50'
            />
            <Skillbar
            percent = '30%'
            titleColor = '#782817'
            icon = "devicon-git-plain-wordmark"
            name = 'Git'
            barColor = '#f1502f'
            />
        </div>
        )
    }
}