import React, { Component } from 'react'
import { Banner } from './Banner/Banner'
import { Navbar } from './Navbar/Navbar';
import { Header } from './Header'
import { ProjectShow } from './Projects/ProjectShow'
import { EducationTimeline } from './EducationTimeline';
import { WorkTimeline } from './WorkTimeline';
import { Skills } from './Skillbar/Skills';
import { ContactMe } from './ContactMe/ContactMe';

export class Main extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             NavbarHeight: ''
        }
        this.handleNavbarHeight = this.handleNavbarHeight.bind(this)
    }
    handleNavbarHeight(height) {
        this.setState({NavbarHeight : height})
    }
    render() {
        return (
            <div id='main'>
            <Banner
                getNavbarHeight={this.state.NavbarHeight}
                />
            <Navbar 
                getNavbarHeight={this.state.NavbarHeight}
                handleNavbarHeight={this.handleNavbarHeight}
            />
              <div id='content'>  
                <div id='projectsWrapperOuter'>
                <Header 
                name='Projects'
                />
                <ProjectShow />
                </div>                
                <div id='skillsWrapper'>
                <Header 
                name='Skills'
                />
                <Skills />
                </div>
                <Header 
                name='CV'
                />
                <div id='timelineWrapper'>
                  <div id='educationTimeline'>
                  <EducationTimeline />
                  </div>
                  <div id='workTimeline'>
                  <WorkTimeline />
                  </div>
                  <div id='faderWrapper'>
                    <div className='scrollFaderTop'></div>
                    <div className='scrollFaderBottom'></div>
                  </div>
                  
                </div>
                <div id='contactMe'>
                <Header 
                name='Contact Me'
                />
                <ContactMe />
                </div>
            </div>
          </div>
        )
    }
}

export default Main
