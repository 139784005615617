import React from 'react';
import './App.css';
import { Main } from './Main'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import RecipeApp from './RecipeApp/App'

const App = () => {
    return (
    <Router>
      <Switch exact>
      <Route path='/' exact component={Main}>
      </Route>
      <Route path='/RecipeApp' component={RecipeApp}/>
      </Switch>
  </Router>
      );
  }
      
export default App;


